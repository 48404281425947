import enterView from 'enter-view'

const SELECTORS = [
  'h1',
  'h2',
  'h3',
  'h4',
  'p',
  'a',

  '.pricing',
  '.description',
]

let animate = function () {
  enterView({
    selector: SELECTORS.join(','),
    enter: el => {
      el.classList.add('animate__animated', 'animate__customFadeInUp')
    }
  })
}

let unanimate = function () {
  const animatedElements = document.querySelector('.animate__animated')

  Array.from(animatedElements).forEach(element => {
    element.classList.remove('animate__animated')
  })
}

// animate()
// up.on('up:fragment:inserted', animate)
// up.on('up:link:follow', unanimate)