up.compiler('[number-counter]', function (numberCounter) {
  const speed = 10000
  const step = 10

  let currentNumber = 0
  let targetNumber = parseInt(numberCounter.innerText)
  let time = Math.ceil(speed / targetNumber)
  let animate = function () {
    if (currentNumber < targetNumber) {
      numberCounter.innerText = currentNumber++
      setTimeout(animate, time)
    } else {
      numberCounter.innerText = targetNumber
    }
  }

  let observer = new IntersectionObserver(function (entries) {
    if (entries[0].isIntersecting) {
      animate()
    }
  }, {threshold: [0]})
  observer.observe(numberCounter)
})
