up.compiler('[locale-switcher]', function(element) {
  up.on(element, 'click', function(event) {
    const localeSwitcherPopup = element.querySelector('.locale-switcher--popup')
    const popup = up.element.createFromSelector('popup', { content: localeSwitcherPopup.innerHTML })

    up.event.halt(event)

    up.layer.open({
      document: popup,
      target: '.locale-switcher--toggles',
      align: 'right',
      class: '-locale-switcher',
      history: false,
      origin: element,
      mode: 'popup',
      size: 'grow',
    })
  })

  element.classList.add('-initialized')
})