up.compiler('.header-theme-switcher', function(themeSwitcher) {
  const THEME_SWITCHER_BUTTON_SELECTOR = '.header-theme-switcher--button'
  const cachedMode = Cookies.get('mode')
  const hours = new Date().getHours()
  const isDayTime = hours > 6 && hours < 20
  const isNightTime = !isDayTime

  if ((cachedMode === 'dark') || (!cachedMode && isNightTime)) {
    document.body.setAttribute('is-dark-mode', '')
  }

  const isDarkModeEnabled = () => document.body.hasAttribute('is-dark-mode')

  const toggleDarkMode = function() {
    if (isDarkModeEnabled()) {
      document.body.removeAttribute('is-dark-mode')
      Cookies.set('mode', 'light', { expires: 7 })
    } else {
      document.body.setAttribute('is-dark-mode', '')
      Cookies.set('mode', 'dark', { expires: 7 })
    }
  }

  up.on(themeSwitcher, 'click', THEME_SWITCHER_BUTTON_SELECTOR, toggleDarkMode)
})
