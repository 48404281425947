up.compiler('[mobile-navigation]', function(element) {
  const opener = element.querySelector('[mobile-navigation-opener]')
  const mobileNavigationContent = element.querySelector('[mobile-navigation-content]').innerHTML

  up.on(opener, 'click', function() {
    up.layer.open({
      document: mobileNavigationContent,
      target: '.mobile-navigation',
      history: false,
      mode: 'drawer',
      size: 'grow',
    })
  })
})