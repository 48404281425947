import Splide from '@splidejs/splide'

up.compiler('.hero-splide', function(element) {
  const splide = new Splide( element, {
    autoplay: 'play',
    interval: 10000,
    pauseOnHover: false,
    perPage: 1,
    type: 'loop',
  }).mount()

  return () => { splide.destroy() }
})