up.compiler('.flash', function(element) {
  const flashRemover = element.querySelector('.flash--remover')

  up.animate(element, 'fade-in', {
    duration: 500,
  })

  up.on(flashRemover, 'click', function() {
    element.remove()
  })
})