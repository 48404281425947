import MenuSpy from "menuspy/dist/menuspy.min"

up.compiler('[data-header]', function(header) {
  // For whatever reason the sections node list is stale otherwise
  up.util.task(() => {
    const sections = [...document.querySelectorAll('[data-section]')]

    const options = {
      rootMargin: `${header.offsetHeight * -0.7}px`,
      threshold: 0
    }
    const menuSpy = new MenuSpy(header, {
      activeClass: '-active',
    })

    let direction = 'up'
    let prevYPosition = 0

    const shouldUpdate = (entry) => {
      if (direction === 'down' && !entry.isIntersecting) {
        return true
      }

      return !!(direction === 'up' && entry.isIntersecting)
    }

    const updateColors = (target) => {
      const theme = target.dataset.section
      header.setAttribute('data-theme', theme)
    }

    const getTargetSection = (entry) => {
      const index = sections.findIndex((section) => section === entry.target)

      if (index >= sections.length - 1) {
        return entry.target
      } else {
        return sections[index + 1]
      }
    }

    const onIntersect = (entries) => {
      entries.forEach((entry) => {
        if (window.scrollY > prevYPosition) {
          direction = 'down'
        } else {
          direction = 'up'
        }

        prevYPosition = window.scrollY

        const target = direction === 'down' ? getTargetSection(entry) : entry.target

        if (shouldUpdate(entry)) {
          updateColors(target)
        }
      })
    }

    const observer = new IntersectionObserver(onIntersect, options)
    sections.forEach((section) => {
      observer.observe(section)
    })

    return () => {
      menuSpy.destroy()
      observer.disconnect()
    }
  })
})