// Entry point for the build script in your package.json
import 'flag-icon-css/css/flag-icon.min.css'
import '@fortawesome/fontawesome-free/css/all'
import 'menuspy/dist/menuspy.min.js'
import '@splidejs/splide/dist/js/splide.min'
import '@splidejs/splide/dist/css/splide.min.css'
import 'unpoly/unpoly.js'
import 'unpoly/unpoly.css'
import Cookies from 'js-cookie'

import './application/config/bootstrap.sass'
import './application/config/root/**/*.sass'
import './application/theme.sass'

import './application/components/**/*.js'
import './application/components/**/*.sass'

window.Cookies = Cookies